<template>
  <div>
    <b-card class="mb-0">
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button variant="primary" to="/inventory-transactions/add">
              <feather-icon icon="PlusIcon" class="mr-50" />
              Add Transaction
            </b-button>
          </div>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <!-- <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group> -->
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="transactions"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(posted)="data">
              <b-badge v-if="data.value === true" variant="primary">
                Posted
              </b-badge>
              <b-badge v-else variant="secondary"> Open </b-badge>
            </template>
            <template #cell(date)="data">
              {{ dayjs(data.value).format("DD/MM/YYYY hh:mm:ss") }}
            </template>
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-edit
                  :to="getTransactionView(data.item.id)"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>View</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-edit
                  :to="getTransactionEdit(data.item.id)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="removeTransaction(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              variant="success"
              label="Spinning"
              block
              v-if="loading"
              style="width: 3rem; height: 3rem"
            ></b-spinner>
          </div>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardImg,
  BCardFooter,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardImg,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      dayjs,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        "id",
        "type",
        "fromWarehouse",
        "toWarehouse",
        "date",
        "posted",
        "actions",
      ],
    };
  },
  methods: {
    ...mapActions("inventoryTransactionModule", [
      "getTransactionListAction",
      "removeTransactionAction",
    ]),
    getProductView(item) {
      return "/inventory-transactions/view/" + item;
    },
    getProductEdit(item) {
      return "/inventory-transactions/edit/" + item;
    },
    removeTransaction(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeTransactionAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted",
            text: "Transaction has been deleted successfully!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    }
  },
  computed: {
    sortOptions() {      
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("inventoryTransactionModule", {
      transactions: "transactions",
      loading: "loading",
    }),
  },
  async mounted() {
    await this.getTransactionListAction()
      .then(() => {
        this.totalRows = this.transactions.length;
      });
  }
};
</script>